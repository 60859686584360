// @import "parts/variables";
@import "parts/variables";
@import "parts/mixins";
@import "parts/elements";
@import "parts/hamburgers/hamburgers";
@import "parts/photoswipe/default-skin/default-skin";
@import "parts/photoswipe/photoswipe";

@import "parts/icons";
@import "parts/nav";
@import "parts/header";
@import "parts/titles";
@import "sections/homepage";
@import "sections/poradnie";
@import "sections/offer";
@import "sections/footer";

*{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:none;
  text-rendering: optimizeLegibility;
  }
a {
  color: #333333;
}
html {
  overflow-x: hidden;
 height: 100%;
 
}
body {
  color: #333333;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
}

h1,h2,h3,h4,h5,h6 {
  color: #333333;

}
.wrapper-default {
  width: 100%;
  padding-right: 8%;
  padding-left: 8%;
  margin-right: auto;
  margin-left: auto;
  @include mq(bp-1920) {
    max-width: 1860px;
  }
}

.container-custom {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include mq(bp-992) {
  max-width: 960px;
  }
  @include mq(bp-1200) {
      max-width: 1180px;
  }

}


