$browser-context: 16px;


@mixin background-2x($path, $ext: "png", $w: auto, $h: auto, $pos: left top, $repeat: no-repeat) {
  $at1x_path: "#{$path}.#{$ext}";
  $at2x_path: "#{$path}@2x.#{$ext}";
  background-image: url("#{$at1x_path}");
  background-size: $w $h;
  background-position: $pos;
  background-repeat: $repeat;

  @media all and (-webkit-min-device-pixel-ratio: 1.5),
    all and (-o-min-device-pixel-ratio: 3/2),
    all and (min--moz-device-pixel-ratio: 1.5),
    all and (min-device-pixel-ratio: 1.5) {
    background-image: url("#{$at2x_path}");
  }
}


@mixin pseudo($pos: absolute, $content: '') {
  content: $content;

  position: $pos;
}

@mixin font($font-size: 16px, $line-height: 18px, $font-weight : 400, $context: $browser-context) {
  @if (unitless($font-size)) {
    $font-size: $font-size * 1px;
  }
  @if (unitless($line-height)) {
    $line-height: $line-height * 1px;
  }
  @if (unitless($context)) {
    $context: $context * 1px;
  }

  font-size: ($font-size / $context ) * 1rem;
  line-height: ($line-height/ $context ) * 1rem;
  font-weight: $font-weight;
}

@mixin letter-spacing($spacing) {
  letter-spacing: ($spacing / 1000) * 1rem;
}

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @if $type==max {
      $width: $width - 0.0625em;
    }

    @media (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin gradient-animation($start, $end, $transTime) {
  background-size: 101%;
  background-image: linear-gradient(45deg, #fff, #fff);
  position: relative;
  z-index: 100;

  &:before {
    background-image: linear-gradient(90deg, $start, $end);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 101%;
    z-index: -100;
    transition: opacity $transTime;
    border-radius: inherit;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

@mixin gradient-animation-start($start, $end, $transTime) {
  background-size: 101%;
  background-image: linear-gradient(45deg, $start, $end);
  position: relative;
  z-index: 100;

  &:before {
    background-image: linear-gradient(45deg, $start, $start);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    transition: opacity $transTime;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
}

@mixin gradient-animation-end($start, $end, $transTime) {
  background-size: 101%;
  background-image: linear-gradient(45deg, $start, $end);
  position: relative;
  z-index: 100;

  
  &:before {
    background-image: linear-gradient(-45deg, $end, $end);
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    width: 100%;
    z-index: -100;
    border-radius: inherit;
    transition: opacity $transTime;
  }

  &:hover {
    -webkit-box-shadow: 0px 0px 10px 0px  $end;
    -moz-box-shadow: 0px 0px 10px 0px  $end;
    box-shadow: 0px 0px 10px 0px  $end;
    &:before {
      opacity: 1;
    }
  }
}

/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
@mixin on-circle($item-count, $circle-size, $item-size) {
  position: absolute;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  >* {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -($item-size / 2);

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform:
          rotate($rot * 1deg) translate($circle-size / 2) rotate($rot * -1deg);
      }

      $rot: $rot+$angle;
    }
  }
}