.bg-img {

  width: 100%;
}

.section-clinics {
  padding: 80px 0;

  .s-clinic {
    position: relative;
    overflow: hidden;
    padding: 0;
    margin-right: -1px;

  }

  .bg-img {
    -webkit-transition: -webkit-transform 0.5s ease;
    -moz-transition: -moz-transform 0.5s ease;
    transition: transform 0.5s ease;
  }

  .s-content {
    color: #fff;
    position: absolute;
    padding: 20px 15px;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    z-index: 1;
    cursor: pointer;

    &::after {
      @include pseudo();
      opacity: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $overlay;
      -webkit-transition: -webkit-opacity 0.5s ease;
      -moz-transition: -moz-opacity 0.5s ease;
      transition: opacity 0.5s ease;
    }

    &:hover {

      &::after {
        opacity: 0;
      }

      text-decoration: none;

      +.bg-img {
        transform: scale(1.1);
      }


    }
  }

  .s-title {
    color: #fff;
    position: relative;
    z-index: 2;
    font-weight: 900;

    &::after {
      @include pseudo;
      width: 50px;
      margin: 0 auto;
      left: 0;
      right: 0;
      bottom: -10px;
      height: 2px;
      background-color: $orange;
    }

    @include letter-spacing(20);
    @include font(17, 20, 800);

    @include mq(bp-1200) {
      @include font(20, 24, 800);
    }

    @include mq(bp-1600) {
      @include font(28, 34, 800);
    }
  }

  .s-description {
    min-height: 70px;
    z-index: 2;
    text-align: center;
    padding: 15px;
    margin-bottom: 0;
    @include font(14, 17);

    @include mq(bp-1200) {
      @include font(13, 16);
      min-height: 100px;
    }

    @include mq(bp-1600) {
      @include font(14, 17);
    }

  }
}


.section-bgimage {
  display: flex;
  align-items: center;

  @include mq(bp-768, max) {
    background: transparent !important;
  }

  padding: 30px 0px 5px 0;

  @include mq(bp-768) {
    padding: 80px 0;
  }

  @include mq(bp-1200) {
    padding: 100px 0;
    min-height: 720px;
  }


  background-position: center center;
  background-size: cover;
  color: #3D3D3D;

  .title {
    position: relative;
    @include font(20, 24, 800);
    padding: 10px 0;
    margin-bottom: 20px;
@include mq(bp-991) {
  @include font(32, 39, 800);
}
    &::after {
      @include pseudo();
      width: 2px;
      height: 25px;
      left: 18px;
      bottom: -25px;
      background-color: $orange;
    }
  }

  .mobile-image {
    margin-left: -30px;
    margin-right: -30px;
    padding-bottom: 15px;

    img {
      width: 100%;
    }

    @include mq(bp-768) {
      display: none;
    }
  }

  .description {
    color: #707070;
    @include font(14, 17);
    padding: 15px 0;

    @include mq(bp-768) {
      padding: 30px 0;
    }

    @include mq(bp-1200) {
      padding: 35px 0;
    }

    position: relative;

    &.with-bg {
      &::before {
        @include pseudo();
        background-color: rgba(255, 255, 255, 0.9);
        position: absolute;
        width: calc(100% + 100px);
        height: 100%;
        top: -8px;
        left: -50px;
        z-index: -1;
      }
    }

  }

  .description-button {
    width: 100%;
    padding-top: 15px;

    @include mq(bp-1200) {
      padding-top: 30px;
    }

  }

  .right,
  .left,
  .center {
    display: flex;

  }

  .right {
    @include mq(bp-560,max) {
      padding-left: 0;
      padding-right: 0;
    }
    justify-content: flex-end;
  }

  .left {
    @include mq(bp-560,max) {
      padding-left: 0;
      padding-right: 0;
    }
    justify-content: flex-start;
  }

  .center {

    justify-content: center;
  }

  .icon {
    max-height: 48px;
  }
}

.slider-wrapper {
  // padding-left: 15px;
  // padding-right: 15px;
  max-width: 1750px;
  margin: 0 auto;
}

.slider-content {

  @include mq(bp-991) {
    max-width: 430px;
  }
}

.slider-description {
  @include font(14, 17);
  color: #707070;
  padding: 25px 0;
  padding-bottom: 0;
  @include mq(bp-768) {
    padding: 25px 0;
  }
}

.slider-title {
  position: relative;
  @include font(32, 39, 800);
  padding: 10px 0;
  margin-bottom: 20px;

  &::after {
    @include pseudo();
    width: 2px;
    height: 25px;
    left: 18px;
    bottom: -25px;
    background-color: $orange;
  }
}

.slider-item {
  @include mq(bp-768, max) {
    background-image: none !important;
  }

  background-size: cover;
  min-height: 500px;
  background-position: center center;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.slider-nav {
  display: flex;
  list-style: none;

  position: relative;
  @include mq(bp-768) {
    margin-top: -60px;
    padding-bottom: 60px;
  }


  li {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    cursor: pointer;
  }

  .slick-active {
    button {
      background-color: $orange;
    }
  }

  button {
    outline: none;
    font-size: 0;
    width: 25px;
    height: 2px;
    border: 0;
    background-color: rgba(51, 51, 51, 0.25);
  }
}



.overlay-more {
  transition: all 0.3s;
  position: absolute;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 51, 51, 0.5);
}


.page-template-page-offer {

  .content-wrapper {
    padding-bottom: 0;
  }

  .clinic-offer-section {
    margin-top: 15px;

    @include mq(bp-991) {
      margin-top: 60px;
    }
  }
}

.clinic-wrapper {
  position: relative;
}

.clinic-offer-section {
  .description-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .clinic-offer-title {
    @include mq(bp-991) {
      position: absolute;
      top: 0;
      right: calc(50% - 170px);
      top: 22px;
      padding: 0;
    }
  }
}

.clinic-offer {
  padding: 0;
  justify-content: center;
  @include mq(bp-560) {
    padding: 30px 0;
  }
  @include mq(bp-991) {
    padding: 100px 15px 15px 15px;
    justify-content: space-between;
  }



  img {
    max-width: 100%;
  }

  @include mq(bp-991, max) {
    li {
      padding: 0;
    }
  }


  .desc-col {
    @include mq(bp-991, max) {
      padding: 15px;

    }

    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .offer-title {
    transition: font-size 0.3s;
    @include font(16, 19, 800);
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &::after {
      @include pseudo();
      width: 45px;
      height: 2px;
      left: 0px;
      bottom: 0px;
      background-color: #FF6928;
    }

  }

  .description {
    @include font(14, 17);
    color: #707070;
  }

  .reverse {

    margin-bottom: 20px;

    @include mq(bp-768) {
      padding: 0 25px;
    }

    @include mq(bp-991) {
      padding-left: 10px;
      padding-right: 34px;

      .offer-content {
        flex-direction: row-reverse;
      }
    }

    .image-container::before {
      right: -9px;
      ;
    }
  }

  .margin-top {
    margin-bottom: 20px;

    @include mq(bp-768) {
      padding: 0 25px;
    }

    @include mq(bp-991) {
      text-align: right;
      padding-right: 10px;
      padding-left: 34px;
      position: relative;
      top: -90px;

      .offer-title {
        &::after {
          left: auto;
          right: 0;
        }
      }
    }

    .image-container::before {
      left: -9px;
    }
  }


  .image-container {
    position: relative;
    padding: 0;
    .offer-img {
      width: 100%;
    }

    &::before {
      @include pseudo();
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      background-color: $orange;
      border: 6px solid #fff;
      border-radius: 50%;

      @include mq(bp-991, max) {
        left: 0 !important;
        right: 0 !important;
        margin: 0 auto;
        bottom: -18px !important;
        top: auto;
      }
    }
  }
  .offer-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    text-decoration: none;
    transition: all 0.3s;
    width: 100%;

    &:hover {
      .overlay-more {
        opacity: 1;
      }
    }

    @include mq(bp-991) {
      flex-direction: row;
      margin-bottom: 30px;
      justify-content: center;
    }

  }
}
.klinika-piekna .offer-content {
  &:hover {
    background-color: #EFE2D7;
  }
}

.klinika-zdrowia .offer-content {
  &:hover {
    background-color: #E9EEF5;
  }
}

.klinika-usmiechu .offer-content {
  &:hover {
    background-color: #E9F3F5;
  }
}

.klinika-leczenia-bolu .offer-content {
  &:hover {
    background-color: #EAE9F5;
  }
}

.title-container {
  padding: 15px 0;

  @include mq(bp-1200) {
    padding: 30px 0;
  }
}

.gray-title {
  color: rgba(51, 51, 51, 0.25);
  text-align: center;
  @include font(20px, 24px, 700);

  @include mq(bp-991) {
    @include font(32px, 40px, 700);
  }

  @include mq(bp-1200) {
    @include font(40px, 48px, 700);
  }
}


.section-title {
  color: #3D3D3D;
  text-align: center;
  @include font(16px, 19px, 800);

  @include mq(bp-991) {
    @include font(28px, 34px, 800);
  }

  @include mq(bp-1600) {
    @include font(32px, 39px, 800);
  }
}

.offer-accordion {
  padding-top: 15px;
  @include mq(bp-991) {
    max-width: 770px;
    padding-top: 30px;
  }

  margin: 0 auto;

  .offer-card {
    margin-bottom: 8px;
  }

  .offer-header {
    transition: all 0.3s;
    background-color: $orange;
    padding: 14px 50px 14px 30px;
    @include mq(bp-768) {
      padding: 18px 50px 18px 40px;
    }
    position: relative;
    color: #fff;
    cursor: pointer;
margin-bottom: 5px;
    &::before {
      @include pseudo();
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      left: -9px;
      background-color: #FF6928;
      border: 7px solid #fff;
      border-radius: 50%;
    }

    &::after {
      @include pseudo();
      pointer-events: none;
      position: absolute;
      transition: all 0.3s;
      right: 20px;
      @include mq(bp-560) {
        right: 28px;
      }
      
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      top: 28px;
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }

    &.collapsed {
      background-color: #FBFBFB;

      &::after {
        border: solid #FF6928;
        border-width: 0 2px 2px 0;
        top: 24px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }

      .title,
      .faq-title {
        color: #333333;
      }
    }

    .title {
      @include font(12, 16, 800);
      @include mq(bp-560) {
      @include font(16, 20, 800);
      }
      @include mq(bp-991) {
        @include font(20, 24, 800);
      }

      color: #fff;
      margin: 0;
    }

  }
}

.loyality-desc {
  @include font(14, 17);
  color: #707070;
}

.offer-body {
  padding: 15px 0;

}

.post-content {
  @include font(14, 17);
  color: #707070;
}

.post-container {
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.post-title {
  transition: font-size 0.3s;
  @include font(16, 19, 800);
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 10px;

  &::after {
    @include pseudo();
    width: 45px;
    height: 2px;
    left: 0px;
    bottom: 0px;
    background-color: #FF6928;
  }
}

.img-container {
  img {
    width: 100%;
    height: auto;
  }
}


.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.aplications {

  @include font(14, 17);

  span {
    width: 100%;
    padding-bottom: 15px;
  }

  a {
    padding-left: 5px;
    img {
      max-width: 140px;
    }
  }

  @include mq(bp-560, max) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
    }

  }

  @include mq(bp-560) {
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 20px;
  }


}

.column-padding {
  padding-left: 7.5px;
  padding-right: 7.5px;
}

.button-container {
&.nf-field-container {
  display: flex;
  justify-content: flex-end;
}
}
 
.page-template-page-contact  {
  .content-wrapper {
    padding-bottom: 0;
  }
}