.fixed-on-mobile {
  @include mq(bp-768, max) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    // z-index: 1030;
  }
}

.display-mobile {
  @include mq(bp-768) {
    display: none!important;
  }
}
.display-desktop {
  @include mq(bp-768,max) {
    display: none!important;
  }
}
.mc-logo {
 display: none;
  @include mq(bp-768) {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin: 10px 0;
    height: 48px;
    position: relative;
    background-image: url(../img/logo.svg);
  }
  @include mq(bp-1200) {
    height: 60px;
  }
}
.mc-logo-link {
  position: absolute;
  width: 100%;
  height: 100%;
}
.hamburger {
  &.close-menu {
    position: absolute;
    right: 15px;
    top: 12px;
  }
  &.hidden {
    display: none!important;
  }
  &:focus {
    outline: none;
  }
}
#main-toggler {
  &.show-in-menu {
    z-index: 4;
  }
}
.menu-container {
    @include mq(bp-768,max) {
      opacity: 0;
      transition: all 0.3s ease-out;
      visibility:hidden;
      &.open {
        visibility:visible;
        display: flex;
        opacity: 1;
      }
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      height: 100vh;
      
     ul {
       width: 100%;
       height: 100%;
     } 
     .navbar-nav.bg-orange {
          background-color: #fff;
          text-align: center;
          justify-content: space-around;
          padding: 60px 0;
          .menu-item {
            display: flex;
          }
          .menu-item a {
            text-decoration: none;
            width: 100%;
            padding: 20px;
            font-weight: 700;
            color: #333333;
            text-decoration: none;
            &:hover {
              background-color: #DCDCDC;
            }
          }
          .menu-item.link-icon a {
            color: $orange;
          }
        }
    
  }


  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  .bg-orange {
    padding: 0.875em 2em;
  }
}


.navbar-nav.bg-orange>.menu-item:last-child>a {
  margin-right: 0;
}

.bg-orange {
  background-color: $orange;
  color: #fff;
}
.container-topbar {
  max-width: 1180px;
  margin: 0 auto;
}
.topbar {
  @include mq(bp-768,max) {
    height: auto;
  }
  background-color: #FBFBFB;
  border-bottom: 1px solid #DCDCDC;
  &__lrmenu {
    display: flex;
    justify-content: space-between;
   
    
  }
}
.nav-left {
  @include mq(bp-768,max) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  .menu-item a {
    display: flex;
    text-decoration: none;
    @include font(12px, 15px, 500);
    color: #333333;
    padding: 15px;
    @include mq(bp-1200) {
      padding: 15px 30px;
    }
    &:hover {
      background-color: #DCDCDC;
      
    }
  }
  .current-menu-item a {
    @include mq(bp-768,max) {
      display: none;
    }
    background-color: #fff;
    border-left: 1px solid #DCDCDC;
    border-right: 1px solid #DCDCDC;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
    &:hover {
      border-bottom: 1px solid #DCDCDC;
    }
    
  }
  .navbar-nav {
    @include mq(bp-768,max) {
    padding: 15px 0;
    }
  }
  .navbar-toggler {
    outline: none;
    padding: 0;
    &.collapsed {
      .mobile-first-item::after {
        top: 7px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        @include mq(bp-768) {
          top: 14px;
          right: 0;
        }
      }
    }
  }
  .mobile-first-item {
    @include font(12px, 15px, 500);
    @include mq(bp-768,max) {
    padding: 6px 30px;
    }
    padding: 15px 30px;
    list-style: none;
    position: relative;
    &::after {
     
      @include pseudo();
      transition: all 0.3s;
      border: solid $orange;
      right: 28px;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      top: 12px;
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
      @include mq(bp-768) {
        right: 0px;
        top: 18px;
      }
        
    }
  }
}

// TOP BAR GLOBAL MENU


.mc-logo {
  img {
    max-width: 100%;
  }

}

.link-icon {
  @include mq(bp-768) {
    display: flex;
    border-right: 1px solid #DCDCDC;

    &:last-child {
      border-right: none;
    }

    a {
      @include font(12px, 15px, 500);
      color: #333333;
      padding: 15px 20px 15px 35px;
      position: relative;
      @include mq(bp-1200) {
        padding: 15px 30px 15px 45px;
      }
      &::before {
        @include pseudo();
        width: 13px;
        height: 13px;
        left: 10px;
        @include mq(bp-1200) {
        left: 25px;
        }
        background-size: contain;
        background-repeat: no-repeat;
      }

      &:hover {
        cursor: pointer;
        background-color: #DCDCDC;
        text-decoration: none;
      }
    }

  }

}

// ICONS
.icon-clientpanel {
  a {
    &::before {
      background-image: url(../img/icons/client_panel.svg);
    }
  }
}

.icon-shop {
  a {
    &::before {
      background-image: url(../img/icons/shop.svg);
    }
  }

}

.icon-register {
  a {
    &::before {
      background-image: url(../img/icons/register.svg);
    }
  }
}

.icon-search {

  background-image: url(../img/icons/search.svg);
  width: 15px;
  height: 15px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 7px;
}

.search-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 0;
  background: transparent;
  @include font(9px, 11px, 500);
  color: rgba(61, 61, 61, 0.5);

  @include mq(bp-991) {
    width: 48px;
    height: 48px;
    border: 1px solid $orange;

  }
  &.display-mobile {
    position: absolute;
    top: 15px;
    left: 15px;
    .icon-search {
      width: 28px;
      height: 28px;
    }
  }
}

.center-elements {
  display: flex;
  justify-content: center;
  align-items: center;
  @include mq(bp-1366,max) {
    padding: 0;
  }
  &-l {
    @include mq(bp-991) {
      justify-content: flex-start;
      padding-left: 35px;
    }
    
    @include mq(bp-560,max) {
      justify-content: center;
      order: 2;
      padding: 5px 0;
    }

  }
  &-r {
    // @include mq(bp-1200,max) {
    //   justify-content: flex-end;
    //   padding-right: 20px;
    // }
    @include mq(bp-768,max) {
      padding-right: 0px;
    }
    @include mq(bp-991) {
      justify-content: flex-end;
     
    }
  }

}
.home .header {
  @include mq(bp-768,max) {
    display: flex;
    flex-direction: column;
    .menu-wrapper {
      order: 1;
    }
    .info-wrapper {
      display: block;
      order: 2;
      
    }
  }
}
.info-wrapper {
  @include mq(bp-768,max) {
      display: none;
  }
}
.header-left,
.header-right {
  display: flex;
  justify-content: space-around;
  align-items: center;

}
.header-right {
  @include mq(bp-768,max) {
    background-color: $orange;
    width: 100%;
    color:#fff;
    br {
      display: none;
    }
  }
}

.call-us-header {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-right: 15px;

  &:after {
    @include pseudo();
    background-image: url(../img/vertical-separator.svg);
    height: 33px;
    right: 0;
    background-size: contain;
    background-repeat: no-repeat;
    width: 4px;
    top: 50%;
    transform: translateY(-50%);
  }

  .number {
    @include font(16px, 20px, 900);
    @include mq(bp-340) {
      @include font(20px, 24px, 900);
    }
    &:hover {
      color:$orange;
      text-decoration: none;
    }
  
  }

  .call-us {
    @include font(12px, 15px, 600);
    padding: 4px ;
    text-align: right;
    &:hover {
      color:$orange;
    }
    &:before {
      @include pseudo();
      background-image: url(../img/icons/phone.svg);
      height: 17px;
      left: 0px;
      background-size: contain;
      background-repeat: no-repeat;
      width: 17px;
      @include mq(bp-360,max) {
        left: -20px;
      }
    }
  }
}

.search-header {
  margin-left: 10px;
}

.open-hour-header {
  padding-left: 10px;
  @include font(12px, 18px, 500);
}

.address-header {
  @include font(12px, 16px, 500);
  padding: 15px;
  @include mq(bp-768) {
    @include font(14px, 18px, 500);
  }
}
.navbar {
  @include mq(bp-768,max) {
    background-color: #fff;
    position: relative;
    width: 100%;
    left: 0;
    top: 0px;
    z-index: 3;
    justify-content: space-between;
    padding: 15px;
    &.fixed {
      position: fixed;
      animation:slide-down 0.7s;
      top: 0;
    }
  }

}



.info-wrapper {
  width: 100%;;
  max-width: 1180px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}

.navbar .menu-item {
  position: relative;
  display: flex;
  align-items: center;
  @include mq(bp-768) {
    padding-right: 35px;

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
    &::after {
      @include pseudo();
      width: 18px;
      right: 10px;
      height: 1px;
      background-color: $orange-light;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  @include mq(bp-991) {
    padding-right: 55px;

    &:last-child {
      padding-right: 0;

      &::after {
        display: none;
      }
    }
    &::after {
      @include pseudo();
      width: 25px;
      right: 15px;
      height: 1px;
      background-color: $orange-light;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  a {
    text-decoration: none;

    @include mq(bp-768) {
      @include font(12px, 15px, 700);
    }
    @include mq(bp-991) {
      @include font(14px, 16px, 700);
    }
    @include mq(bp-1200) {
      @include font(16px, 18px, 700);
    }
    color: #fff;
  }
}

.navbar .hamburger {
  @include mq(bp-768) {
    display: none !important;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height .35s ease;
  -o-transition: height .35s ease;
  transition: height .35s ease;
}



@keyframes slide-down {
  0% {
      opacity: 0;
      transform: translateY(-100%);
  } 
  100% {
      opacity: 1;
      transform: translateY(0);
  } 
}