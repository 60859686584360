.icon {
  &--fb {
    background-image: url('../img/icons/icon_fb.svg')
  }
  &--insta {
    background-image: url('../img/icons/icon_insta.svg')
  }
  &--yt {
    background-image: url('../img/icons/icon_yt.svg')
  }

}