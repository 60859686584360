.offer-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @include font(14, 18);
  @include mq(bp-991) {
    flex-direction: row;
    // align-items: flex-end;
    // justify-content: center;
    // min-height: 475px;
    // margin-bottom: 30px;
  }

  &__title {
    display: flex;
    align-items: center;
    color: $orange;
    padding: 7px 30px;

    @include mq(bp-991) {
      padding: 15px;
    }
  }

  &__desc {
    padding: 7px 30px 7px 35px;
    @include mq(bp-991) {
      padding: 15px;
    }
  }

  &__right {
    @include mq(bp-991) {
    width: 40%;
    }
  }
  &__image {
    margin-right: -50px;
    @include mq(bp-991) {
      width: 60%;
    }

    img {
      width: 100%;
    }
  }

  .icon {
    height: 15px;
    width: 28px;
    margin-right: 5px;

    @include mq(bp-991) {
      margin-right: 10px;
    }

    display: flex;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    &.icon_body {
      background-image: url(../img/icons/icon_body.svg);
    }

    &.icon_callendar {
      background-image: url(../img/icons/icon_callendar.svg);
    }

    &.icon_confirm {
      background-image: url(../img/icons/icon_confirm.svg);
    }

    &.icon_invasiveness {
      background-image: url(../img/icons/icon_invasiveness.svg);
    }

    &.icon_sadface {
      background-image: url(../img/icons/icon_sadface.svg);
    }

    &.icon_syringe {
      background-image: url(../img/icons/icon_syringe.svg);
    }
  }
}

.offer-price {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 15px 0;
  
  .price {
    margin-left: 15px;
    @include font(22, 27, 800);
    color: #3D3D3D;
  }
  @include mq(bp-991) {
    padding: 50px 0;
    .price { 
      margin-left: 35px;
    }
  }

  
}

.offer-description-container {
  position: relative;
  padding: 15px 0;

  @include mq(bp-991) {
  //  max-width: 60%;
    padding: 30px;
  }

  background-color: rgba(255, 255, 255, 0.9);
}

.default-text-container {
  padding-top: 30px;

  @include mq(bp-1200) {
    padding: 25px 15px;
  }

}
.default-text-title {
  color: #3D3D3D;
  @include font(16, 22, 800);
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 10px;

  &::after {
    @include pseudo();
    width: 46px;
    height: 2px;
    left: 0px;
    bottom: 0px;
    background-color: #FF6928;
  }
}
.default-desc {
  @include font(14, 22, 500);
  color: #707070;

  img {
    width: 100%;
    height: auto;
  }
}
.contraindication {
  background-color: rgba(255, 105, 40, 0.1);
  padding: 30px 15px;
  @include mq(bp-768) {
    padding: 20px;
    padding-left: 95px;
  }
  color: $orange;
  position: relative;
  margin-bottom: 15px;
  @include mq(bp-1200) {
    padding: 30px 95px;
  }
  .title {
    @include font(24,29,800);
    padding-bottom: 20px;
  }
  .description {
    @include font(14,24,500);
    ul {
      padding-left: 16px;
    }
  }
  .icon-info {
    height: 40px;
    width: 50px;
    position: absolute;
    right: 15px;
    top: 15px;
    background-image: url('../img/icons/icon_info_mobile.svg');
    @include mq(bp-768) {
      background-image: url('../img/icons/icon_info.svg');
      top: 24px;
      left: 30px;
      right: auto;
    }
   background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
.clinic-slider  {
 // max-height: 700px;
}
.b-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 1160px;
  }

  @media (min-width: 1200px) {
    max-width: 1320px;
  }

  @media (min-width: 1440px) {
    max-width: 1280px;
  }

}

.gallery-wrapper {
  padding: 30px 0;
}
.gallery {
  padding: 15px 0;
 display: flex;
 img {
   width: 100%;
 }
 .gallery-thumbnails {
   @include mq(bp-991) {
    padding-top: 40px;
   }
 }
 .first-image {
   @include mq(bp-560,max) {
   padding: 0;
   }
 }
}
.wrapper-fluid {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    @include mq(bp-1200) {
      padding-right: 4%;
      padding-left: 4%;
    }
}
.call-to-action {
  display: flex;
  padding: 30px;
  @include mq(bp-1200) {
    padding: 30px 50px;
  }
  align-items: center;
  @include mq(bp-768) {
    min-height: 278px;
  }

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include mq(bp-560,max) {
    background-image: none!important;
    padding: 0;
    .description {
      padding: 30px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .mobile-image {
    img {
      width: 100%;;
    }
  }
  @include mq(bp-560) {
    .mobile-image {
      display: none;
    }
  }
  .title {
    color: #3D3D3D;
    padding-bottom: 20px;
    @include font(26,32,800);
    @include mq(bp-991) {
      @include font(32,39,800);
    }
  
  }
}

.ups {
  background-image: url(../img/404.jpg);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-position: center center;
  width:100%;
  height: 100vh;
  .description-button {
    padding-top: 20px;
  }
  .big-number {
    @include font(90,94,800);
    position: relative;
    padding-bottom: 20px;
   margin-bottom: 20px;
    &::after {
      content: "";
      position: absolute;
      width: 46px;
      height: 2px;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0px;
      background-color: #FF6928;
    }
    color: #333333;
  }
}


.poradnia-wrapper {
  margin-bottom: 15px;
  @include mq(bp-991) {
  margin-bottom: 20px;
  }
  .post-link {
    text-decoration: none;
  
    &:hover {
      .post-image {
        transform: scale(1.07);
      }
      .overlay-more {
        opacity: 1;
      }
    }
  }
  .post-image {
    position: relative;
    transition: all 0.3s;
    display: block;
    .image {
      width: 100%;
    }
   

  }
.poradnia-title {
  @include font(14,18,800);
  padding: 15px 0;
  @include mq(bp-991) {
    @include font(16,20,800);
    padding: 25px 0;
  }
  @include mq(bp-1600) {
    @include font(20,24,800);
    padding: 30px 0;
  }
  
  text-align: center;
  
}

}





.page-id-294 .section-columns.with-bottom-padding {
  padding-top: 30px;
}
.page-id-514 {
.title-container {
  padding-bottom: 60px;
}
}

.related-posts {
  margin-top: 30px;

  @include mq(bp-991) {
    margin-top: 50px;
    padding: 50px 0;
  }
  a {
    transition: all 0.3s;
    text-decoration: none;
    &:hover {
      color: $orange;
    }
  }
  .bottom-dot {
    display: block;
    position: relative;
  }
  .h3 {
    padding-top: 15px;
    @include font(16,19,800); 
    text-align: center;
  }
  img {
    width: 100%;
  }
  article {
    margin-top: 30px;
  }
}