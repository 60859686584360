.poradnie-description {
  // display: flex;
  // flex-direction: column;
  @include font(14, 18);
  align-items: center;

  @include mq(bp-991) {
    margin-bottom: 30px;
  }

  position: relative;

  .default-title {
    position: relative;
    padding-bottom: 14px;
    margin-bottom: 15px;
    @include font(16, 19, 800);

    @include mq(bp-560) {}

    &:after {
      content: "";
      position: absolute;
      width: 45px;
      height: 2px;
      left: 0px;
      bottom: 0px;
      background-color: #FF6928;
    }
  }

  .description {
    @include font(14, 17);
    color: #707070;
  }

  &__image {
    // @include mq(bp-991) {
    //   position: absolute;
    //   left: 0;
    //   top: 0;
    //   width: 75%;
    //   //   max-width: 550px;
    // }

    // @include mq(bp-1200) {
    //   width: 65%;
    // }

    img {
      width: 100%;
    }
  }
}

.poradnie-description-container {
  position: relative;
  padding: 15px ;

  @include mq(bp-991) {
   margin-left: -80px;
    padding: 60px 30px;
  }

  background-color: rgba(255, 255, 255, 0.9);
}

.doctors-container {
  text-align: center;
  padding: 15px 0;

  .doctors-title {
    padding: 15px 0;
    @include font(14, 26, 500);

    @include mq(bp-991) {
      @include font(16, 26, 500);
      padding: 30px 0;
    }

    &.team {
      position: relative;
      padding: 15px 0;
      @include font(14, 26, 500);
      @include mq(bp-991) {
      @include font(15, 26, 700);
      margin-bottom: 15px;
      }
      &::after {
          @include pseudo();
          bottom: 0;
          width: 46px;
          height: 2px;
          margin: 0 auto;
          left: 0;
          right: 0;
          background-color: $orange;
      }
    }
  }

  .team-col {
    margin-bottom: 15px;
  }

  .team-desc {
    color: #707070;
    @include font(14, 18, 500);
  }

  .picture {
    display: flex;
    align-items: center;
  }

  a {
    text-decoration: none;

    &:hover {
      .overlay-more {
        opacity: 1;
      }
    }
  }

  .image {
    width: 100%;
  }
}

.responsive-table {



  // More practical CSS...
  // using mobile first method (IE8,7 requires respond.js polyfill https://github.com/scottjehl/Respond)

  $breakpoint-alpha: 768px; // adjust to your needs

  .rwd-table {
    margin: 1em 0;
    min-width: 100%; // adjust to your needs

    tr {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }

    th {
      display: none; // for accessibility, use a visually hidden method here instead! Thanks, reddit!   
    }

    td {
      display: block;
      color: $orange;

      &:first-child {
        padding-top: .5em;
        color: #333333;
        @include font(12, 16, 500)
      }

      &:last-child {
        padding-bottom: .5em;
      }

      &:before {
        content: attr(data-th)": "; // who knew you could do this? The internet, that's who.
        font-weight: bold;

        // optional stuff to make it look nicer
        width: 6.5em; // magic number :( adjust according to your own content
        display: inline-block;
        // end options

        @media (min-width: $breakpoint-alpha) {
          display: none;
        }
      }
    }

    th,
    td {
      text-align: left;
      @include font(14, 18, 500);

      @media (min-width: $breakpoint-alpha) {
        display: table-cell;
        padding: .25em .5em;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

    }


  }


  // presentational styling



  .rwd-table {

    color: #fff;
    border-radius: .4em;
    overflow: hidden;

    td {

      @include mq(bp-768, max) {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        margin-bottom: 10px;
        // padding: 15px;

        &:before {
          display: flex;
          text-align: center;
          align-items: center;
          justify-content: center;
          padding: 15px;
          width: 100%;
          background-color: #fff;
          margin-bottom: 15px;
        }
      }
    }

    tr {
      border: none;

      &:nth-child(even) {
        background: #FBFBFB;
      }

      @include mq(bp-768, max) {
        background: #FBFBFB;
      }
    }

    th,
    td {


      @media (min-width: $breakpoint-alpha) {
        padding: 1em !important;
        text-align: center;
      }
    }

    th,
    td:before {
      @include font(14, 19, 500);
      color: rgba(51, 51, 51, 0.35);
    }
  }
}

.specialists,
.agenda {
  padding: 30px 0;
}

.eo_custom_popover {
  position: relative !important;
}

.stars-section {
  padding: 15px 0;
}

.star-wrapper {
  align-items: center;

  @include mq(bp-768) {
    margin-bottom: 60px;
  }

  margin-bottom: 30px;
}

.star-container {
  padding: 30px 15px;
  padding-top: 60px;
  margin-top: -30px;
  background-color: rgba(255, 255, 255, 0.9);

  @include mq(bp-768, max) {
    position: relative;

    &:before {
      @include pseudo();
      width: 2px;
      height: 33px;
      background-color: #FF6928;
      left: 30px;
      top: 0px;
      transform: translateY(-50%);

    }
  }


  @include mq(bp-768) {
    display: flex;
    min-height: 350px;
    flex-direction: column;
    justify-content: center;
    min-height: 350px;
    margin-left: -20%;
    padding: 40px;
  }
}

.left-image-container {
  width: 100%;

  @include mq(bp-768) {
    -ms-flex: 0 0 64%;
    flex: 0 0 64%;
    max-width: 64%;
  }

  img {
    width: 100%;
  }

}

.right-content {
  width: 100%;

  @include mq(bp-768) {
    -ms-flex: 0 0 34%;
    flex: 0 0 34%;
    max-width: 34%;
  }
}

.star-title {
  @include font(16, 19, 800);
  position: relative;
  margin-bottom: 15px;

  @include mq(bp-768) {
    &:before {
      @include pseudo();
      width: 46px;
      height: 2px;
      background-color: #FF6928;
      left: -64px;
      top: 50%;
      transform: translateY(-50%);

    }
  }
}

.star-desc {
  @include font(14, 22, 500);
  font-style: italic;
  color: #707070;
}