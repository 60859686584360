.overlay-search {
  height: 100%;
  width: 100%;
  display: none;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  background-color: rgba(255, 255, 255, 0.9);

  @include mq(bp-768) {

    .hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner::before,
    .hamburger.is-active .hamburger-inner::after {
      background-color: #fff;
    }

    height: auto;
    padding-top: 150px;
    padding-bottom: 50px;
    background-color: $orange;

  }

  /* Black with a little bit see-through */
  &.closebtn {
    @include mq(bp-768, max) {
      top: 15px;
      right: 15px;
    }

    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: white;

    &:hover {
      color: #ccc;
    }
  }

  .input-search {
    padding: 15px;
    font-size: 17px;
    border: none;
    float: left;
    width: 80%;
    border-bottom: 1px solid gray;

    &:focus {
      outline: none;

    }

    @include mq(bp-768) {
      color: #fff;
      border-bottom: 1px solid #fff;
      background-color: transparent;

      &:focus {
        outline: none;
        border: 1px solid #fff;
      }

      &::placeholder {
        color: #fff;
      }
    }
  }

  .hamburger {
    float: right;
    position: absolute;
    right: 15px;
    top: 15px;
    width: auto;

    @include mq(bp-768) {
      right: 25px;
      top: 25px;

    }

    border: none;
    cursor: pointer;

    &:hover {
      background: $orange;

      .hamburger-inner {
        background-color: #fff;

        &:before,
        &:after {
          background-color: #fff;
        }
      }
    }
  }

  .eo_custom_popover {
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 80%;

    .eo_live_search_query {
      color: #fff;
      text-align: left;
    }

    .fa,
    .eo_posts_list li a h5 {
      color: rgba(255, 255, 255, 0.5);
      @include font(16px, 18);
    }
  }

  .eo_custom_popover .eo_live_search_query:after,
  .eo_custom_popover .eo_live_search_query:before {
    display: none;
  }

  .eo_posts_list li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    &:hover {
      border-bottom: 1px solid rgba(255, 255, 255, 0.6);

      a h5 {
        color: #fff;
      }
    }
  }

  .eo_posts_list li a:after {
    color: #fff;
  }

  .eo_nothing_found h4 {
    color: #fff;
    @include font(16, 18, 600);
  }
}

/* The content */
.overlay-content {
  position: relative;
  top: 46%;
  width: 80%;
  text-align: center;
  margin-top: 30px;
  margin: auto;
}


.btn {
  border-radius: 0;
  @include font(13, 16, 700);

  @include mq(bp-991) {
    @include font(14, 17, 700);
  }

  &.submit {
    background-color: $orange;
    color: #fff;
    padding: 8px 30px;
    position: relative;

    &:hover {
      -webkit-box-shadow: 0px 0px 32px -5px rgba(255, 105, 40, 1);
      -moz-box-shadow: 0px 0px 32px -5px rgba(255, 105, 40, 1);
      box-shadow: 0px 0px 32px -5px rgba(255, 105, 40, 1);
    }
  }


  &__search-submit {
    width: 35px;
    height: 35px;

    font-size: 0;
    background-size: contain;
    background-image: url(../img/icons/search.svg);
    background-repeat: no-repeat;

    @include mq(bp-768) {
      background-image: url(../img/icons/search_white.svg);
    }
  }

  &.orange {
    background-color: $orange;
    color: #fff;
    padding: 8px 30px;
    position: relative;

    &:hover {
      -webkit-box-shadow: 0px 0px 32px -5px rgba(255, 105, 40, 1);
      -moz-box-shadow: 0px 0px 32px -5px rgba(255, 105, 40, 1);
      box-shadow: 0px 0px 32px -5px rgba(255, 105, 40, 1);
    }
    &.send-form {
      padding: 15px 45px;
      
      text-transform: uppercase;
    }

  }

  &.orange_with_arrow {
    background-color: $orange;
    color: #fff;
    padding: 14px 80px 14px 30px;
    position: relative;

    &::after {
      @include pseudo();
      background-image: url('../img/icons/arrow-right.svg');
      height: 10px;
      width: 42px;
      right: 15px;
      top: 18px;
      background-size: contain;
      background-repeat: no-repeat;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-fill-mode: forwards;
      animation-fill-mode: forwards;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
    }

    &:hover {
      &::after {
        animation-name: bounce-arrow;
        -moz-animation-name: bounce-arrow;
      }
    }
  }
}


@keyframes bounce-arrow {

  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0)
  }

  40% {
    -webkit-transform: translateX(-10px);
    -ms-transform: translateX(-10px);
    transform: translateX(-10px)
  }

  60% {
    -webkit-transform: translateX(-5px);
    -ms-transform: translateX(-5px);
    transform: translateX(-5px)
  }
}



.section-columns {

  &.with-padding {
    padding: 30px 0;

    @include mq(bp-991) {
      padding: 50px 0;
    }

    @include mq(bp-1200) {
      padding: 80px 0;
    }

  }

  &.with-bottom-padding {
    padding-bottom: 30px;

    @include mq(bp-991) {
      padding-bottom: 50px;
    }

    @include mq(bp-1200) {
      padding-bottom: 80px;
    }
  }

  .content {
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;

    &::after {
      @include pseudo();
      opacity: 1;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $overlay;
      -webkit-transition: -webkit-opacity 0.5s ease;
      -moz-transition: -moz-opacity 0.5s ease;
      transition: opacity 0.5s ease;
    }

    &:hover {
      &::after {
        opacity: 0;
      }

      text-decoration: none;

      .bg-img {
        transform: scale(1.1);
      }
    }
  }

  .bg-img {
    -webkit-transition: -webkit-transform 2s linear;
    -moz-transition: -moz-transform 2s linear;
    transition: transform 2s linear;
  }

  .s-content {
    color: #fff;
    position: absolute;
    padding: 30px;
    top: 0;
    height: 100%;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    z-index: 1;
    text-decoration: none;
    cursor: pointer;

  }

  .s-title {
    color: #fff;
    position: relative;
    z-index: 2;
    font-weight: 900;

    &::after {
      @include pseudo;
      width: 50px;
      left: 0;
      bottom: -10px;
      height: 2px;
      background-color: $orange;
    }

    @include letter-spacing(20);
    @include font(17, 20, 800);

    @include mq(bp-1200) {
      @include font(20, 24, 800);
    }

    @include mq(bp-1600) {
      @include font(28, 34, 800);
    }
  }

  .p-title {
    color: #fff;
    z-index: 2;
    font-weight: 900;
    margin: 0;
    @include letter-spacing(20);
    @include font(17, 20, 800);

    @include mq(bp-1200) {
      @include font(20, 24, 800);
    }

    @include mq(bp-1600) {
      @include font(28, 34, 800);
    }
  }

  .p-description {
    z-index: 2;
    padding: 10px 0;
    margin-bottom: 0;
    position: relative;

    &::after {
      @include pseudo;
      width: 50px;
      left: 0;
      bottom: -10px;
      height: 2px;
      background-color: $orange;
    }

    @include font(14, 17);

    @include mq(bp-1200) {
      @include font(13, 16);
    }

    @include mq(bp-1600) {
      @include font(14, 17);
    }
  }

  .s-description {
    z-index: 2;
    padding: 10px 0;
    margin-bottom: 0;
    @include font(14, 17);

    @include mq(bp-1200) {
      @include font(13, 16);
    }

    @include mq(bp-1600) {
      @include font(14, 17);
    }
  }

  .description-button {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(bp-991) {
      margin-top: 60px;
    }
  }
  &.information-boxes {
   
    .p-description {
   
      @include font(12, 15, 500);
  
      @include mq(bp-1200) {
        @include font(16, 18, 500);
      }
  
      @include mq(bp-1600) {
        @include font(18, 24,500);
      }
    }
  }
}


.main-breadcrumbs {
  @include mq(bp-768, max) {
    display: none;
  }

  @include font(12, 15, 500);
  padding: 25px 0;
  padding-top: 20px;

  a {
    text-decoration: none;

    &:hover {
      color: $orange;

    }
  }

  .separator {
    color: rgba(51, 51, 51, 0.25);
    padding: 0 5px;
  }
}


.promo-list {
  width: 100%;
  list-style: none;
  padding: 30px 0;
  max-width: 770px;
  margin: 0 auto;
  .promo-title {
    color: $orange;
    @include font(20, 24, 800);
    margin-right: 15px;
  }

  .promo-desc {
    color: #707070;
    @include font(14, 17);
  }

  .s-content-list {
    text-decoration: none;
    background-color: #FBFBFB;
    padding: 17px 30px;
    padding-right: 70px;
    width: 100%;
    display:flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
@include mq(bp-768) {
  display: block;
}
    &.team {
      @include font(20, 24, 800);
      padding-left: 40px;

      color: #3D3D3D;

      &::before {
        content: "";
        position: absolute;
        z-index: 2;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        left: -8px;
        background-color: #FF6928;
        border: 6px solid #fff;
        border-radius: 50%;
      }

      &:hover {
        color: $orange;
      }

    }

    &::after {
      @include pseudo();
      transition: opacity 0.3s;
      opacity: 0.25;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      background-image: url(../img/icons/arrow_right.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 45px;
      height: 9.4px;
    }

    &:hover {
      background-color: #F7F7F7;

      &::after {

        opacity: 1;
      }
    }
  }
}

.home .content-wrapper {
  padding-bottom: 0;
}

.content-wrapper {
  padding-bottom: 30px;

  @include mq(bp-991) {
    padding-bottom: 100px;
  }

}

.default-list {
  margin: 0;
  padding: 0;
  list-style: none;

  &.pricing {
    max-width: 780px;
    margin: 0 auto;

    li {
      margin-bottom: 10px;
    }

    .default-list__link {
      @include font(14, 18, 800);

      @include mq(bp-768) {
        @include font(20, 22, 800);
      }
    }
  }

  li {
    list-style: none;
    margin-bottom: 5px;
  }

  &__link {
    text-decoration: none;
    background-color: #FBFBFB;
    @include font(14, 18, 800);

    @include mq(bp-768) {
      @include font(16, 18, 800);
    }

    padding: 17px 40px;
    width: 100%;
    display: block;
    position: relative;
    transition: all 0.3s;

    &::after {
      content: "";
      position: absolute;
      transition: opacity 0.3s;
      opacity: 0.25;
      top: 50%;
      transform: translateY(-50%);
      right: 15px;
      background-image: url(../img/icons/arrow_right.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 35px;
      height: 7px;

      @include mq(bp-768) {
        width: 45px;
        height: 9.4px;
      }
    }

    &:hover {
      background-color: #EFE2D7;
      color: $orange;
      text-decoration: none;

      &::after {
        opacity: 1;
      }
    }

    &.pricing {
      &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        left: -7px;

        margin: 0 auto;
        width: 18px;
        height: 18px;
        background-color: #FF6928;
        border: 6px solid #fff;
        border-radius: 50%;
      }
    }
  }

  .price-list-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 15px 12px 15px;
    background-color: #FBFBFB;
    margin-bottom: 5px;

    .title {
      @include font(11, 14);
    }

    .price {
      @include font(11, 14, 800);
      padding-left: 15px;
      text-align: right;
    }

    @include mq(bp-768) {
      padding: 12px 30px 12px 40px;

      .title {
        @include font(14, 18);
      }

      .price {
        min-width: 130px;
        @include font(14, 18, 800);
      }
    }

  }



}


.bottom-dot {
  position: relative;

  &::before {
    @include pseudo();
    z-index: 1;
    bottom: -9px;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 18px;
    height: 18px;
    background-color: #FF6928;
    border: 6px solid #fff;
    border-radius: 50%;
  }

}



.nav-alphabetical {
  display: flex;
  justify-content: center;
  position: relative;

  @include mq(bp-768, max) {
    padding-bottom: 15px;
    @include font(12, 14, 800);

    .alph-wrapper {
      position: absolute;
      top: 36px;
      width: 190px;
      z-index: 2;
      background-color: #fff;
      width: 190px;
      text-align: center;
    }

    .navbar-toggler {
      padding: 10px 45px;
      padding-left: 10px;
      border: 1px solid $orange;
      border-radius: 0;
      width: 190px;
      position: relative;

      &::after {
        @include pseudo();
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;

        border-top: 5px solid #000;
      }
    }

  }

  .mobile-first-item {
    text-transform: uppercase;
    @include font(12, 14, 800);
    list-style: none;

    &.not-changed {
      color: #707070;
      font-size: 10px;
      text-transform: inherit;
    }
  }
}

.alph-wrapper {
  justify-content: center;

  @include mq(bp-768) {
    padding-bottom: 30px;
  }
}

.alphabetical-sort {
  margin: 0;

  @include mq(bp-768, max) {
    flex-direction: column;
  }

  display: flex;
  /* width: 100%; */
  padding: 0;
  list-style: none;
  text-transform: uppercase;
  justify-content: center;

  .alph-el {
    @include font(14, 18, 500);
    padding: 10px 6px;
    margin-right: 4px;
    cursor: pointer;

    @include mq(bp-768, max) {
      @include font(12, 14, 800);
    }


  }
}

.tease {
  text-align: center;

  @include mq(bp-768) {
    margin-bottom: 50px;
  }
}

.post-img-container {
  position: relative;
  display: block;

  &::before {
    @include pseudo();
    height: 25px;
    width: 2px;
    background-color: $orange;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -15px;

  }

  &:hover {
    .overlay-more {
      opacity: 1;
    }
  }

  .image {
    width: 100%;
  }
}

.post-default-title {
  color: #333333;
  @include font(20, 28, 700);
  margin-bottom: 20px;

  a {
    text-decoration: none;

    &:hover {
      color: #000
    }
  }
}

.post-short {
  color: #707070;
  @include font(14, 18, 500)
}

.post-date {
  color: $orange;
  padding: 15px 0;
  margin-top: 15px;
  display: block;
  @include font(12, 14);
}

.pagination-block {
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

  .btn {}
}

.page-numbers {
  padding: 5px;

  &.current {
    font-weight: bolder;
    color: $orange;
  }
}

.pagination {
  display: flex;
  align-items: center;

  a {
    text-decoration: none;

    &:hover {
      color: $orange;
    }
  }
}

.article-body {
  color: #707070;
  padding: 15px 0;

  @include mq(bp-991) {
    padding: 40px 0;
  }

  @include font(14, 22, 500);

  h2 {
    @include font(15, 20, 800);

    @include mq(bp-991) {
      @include font(24, 32, 800);
      margin-bottom: 15px;
    }

  }
}

.title-recent-post {
  @include font(19, 23, 800);
  text-align: center;
  padding: 15px 0;

  @include mq(bp-991) {
    @include font(32, 39, 800);
    padding: 50px 0;
  }

}

.pswp__button--arrow--left:before,
.pswp__button--arrow--right:before {
  background-color: transparent !important;
}





.slick-prev,
.slick-next {
  z-index: 2;

  &:before {
    @include pseudo();
    content: '';
    z-index: 2;
    height: 60px;
    width: 60px;
    background-size: contain;
  }

}



#recent-post {


  .slick-prev,
  .slick-next {
    &:before {
      @include pseudo();
      top: -85px;
    }
  }

  .slick-next {
    &:before {
      background-image: url(../img/icons/icon_right.svg);
      right: 46px;
    }
  }

  .slick-prev {
    &:before {
      content: '';
      background-image: url(../img/icons/icon_left.svg);
      left: 46px;
    }
  }
}



#related-posts {



  .slick-prev,
  .slick-next {
    &:before {
      @include pseudo();
      top: -30px;
    }
  }
  
  .slick-next {
    &:before {
      content: '';
      background-image: url(../img/icons/icon_right.svg);
        right: 26px;
      @include mq(bp-768) {
        right: 130px;

      }
    }
  }

  .slick-prev {
    &:before {
      background-image: url(../img/icons/icon_left.svg);
      content: '';
      left: 26px;
      @include mq(bp-768) {
       left: -25px;
      }
     
    }
  }
}

.copyright {
  background-color: #333333;
  color: rgba(255, 255, 255, 0.5);
  padding: 15px 0;

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    align-items: center;

    img {
      padding: 2px 5px;
    }
  }

  a {
    color: rgba(255, 255, 255, 0.5);
  }

  @include font(12, 14);
}

.contact-header {
  .container {
    padding-left: 0px;
    padding-right: 0px;
  }

  @include mq(bp-560) {
    padding-left: 15px;
    padding-right: 15px;
    min-height: 500px;
    background: url(../img/contact_header.jpg) no-repeat center;
  }

  &.loyality {
    position: relative;

    @include mq(bp-560) {
      background: url(../img/loyality_bg.jpg) no-repeat center;
      margin-bottom: 30px;
    }
  }

  background-size: cover;
  display: flex;
  align-items: center;

  .mobile-img {
    width: 100%;
    margin-bottom: 30px;

    @include mq(bp-560) {
      display: none;
    }
  }
}

.scontact--title {
  position: relative;
  padding-bottom: 14px;
  margin-bottom: 15px;
  font-size: 1rem;
  line-height: 1.1875rem;
  font-weight: 800;

  &:after {
    content: "";
    position: absolute;
    width: 45px;
    height: 2px;
    left: 0px;
    bottom: 0px;
    background-color: #FF6928;
  }
}

.default-contact-title {
  position: relative;

  &.absolute {
    font-family: 'Montserrat', sans-serif;
    top: -85px;
    position: absolute;
  }

  @include font(22, 27, 800);
  padding-bottom: 35px;
  margin-bottom: 15px;

  @include mq(bp-991) {
    @include font(32, 39, 800);
    color: #3D3D3D;
    padding-bottom: 35px;
    margin-bottom: 15px;
  }

  &:after {
    content: "";
    position: absolute;
    width: 2px;
    height: 25px;
    left: 18px;
    bottom: 0px;
    background-color: #FF6928;
  }
}

.header-address {
  font-size: .875rem;
  line-height: 1.0625rem;
  font-weight: 400;
  color: #707070;
  padding-bottom: 10px;
}

.s-contact-desc {
  font-size: .875rem;
  line-height: 1.0625rem;
  font-weight: 400;
  color: #707070;
  padding-bottom: 10px;
}

.contact-elements {
  padding: 80px 0;
}

.contact-depart {
  margin-bottom: 50px;
}

.s-contact-link {
  font-size: .875rem;
  line-height: 1.0625rem;
  font-weight: 400;
  margin-bottom: 5px;
  color: $orange;
  display: block;

  &:hover {
    color: #000;
    text-decoration: none;
  }
}

#contact-map {
  min-height: 500px;

  .leaflet-popup-tip-container {
    display: none;
  }

  .leaflet-popup-content-wrapper,
  .leaflet-popup-tip {
    border-radius: 0;
  }

  .leaflet-popup-content {
    display: flex;
    align-items: center;
    margin: 20px;
    line-height: 1.4;
    min-width: 400px;
    min-height: 60px;
    position: relative;
  }
}

.faq-title {
  @include font(12, 15, 800);

  @include mq(bp-991) {
    @include font(16, 19, 800);
  }

  color: #fff;
}

.faq-body {
  padding: 15px;
  @include font(14, 22, 500);
  color: #707070;
}

.default-form {
  padding: 30px 15px;
@include mq(bp-991) {
  padding: 60px 15px;
  padding-bottom: 0;
}
  input {
    border: 1px solid rgba(255, 105, 40, 0.25);
    padding: 12px 25px;
    width: 100%;
    @include font(14, 18, 500);

    &:focus {
      border: 1px solid rgba(255, 105, 40, 1);
      outline: none;
    }

    &[type="checkbox"] {
      width: auto;

    }

    &.wpcf7-not-valid {
      border-color: #f00;
    }
  }

  .wpcf7-list-item-label {
    @include font(12, 16);
    color: rgba(51, 51, 51, 0.4);
  }

  label {
    width: 100%;
  }

  .label-text {
    padding-left: 20px;
    @include font(12, 14);
    color: $orange;
    margin-bottom: 10px;
  }

  .date-birth {
    @include mq(bp-991) {
      display: flex;
      align-items: center;

    }

    &>span {
      @include mq(bp-991) {
        padding-left: 0px;
        color: rgba(255, 105, 40, 0.55);
      }

      padding-left: 20px;
      color:rgba(255, 105, 40, 1);
      @include font(12, 14);
      padding-right: 10px;
      margin-bottom: -10px;
      min-width: 110px;
    }
  }

  span.wpcf7-not-valid-tip {
    @include font(12, 16, 500);
  }

  div.wpcf7-validation-errors,
  div.wpcf7-acceptance-missing {
    border: 1px solid rgba(255, 105, 40, 1);
    @include font(12, 16);
    padding: 15px;
  }

  .form-title {
    @include font(16, 20, 500);
    margin-bottom: 20px;

    @include mq(bp-991) {
      margin-bottom: 50px;
      @include font(18, 22, 500);
    }
  }

  @include mq(bp-991) {
    .padding-right {
      padding-right: 30px;
    }

    .padding-left {
      padding-left: 30px;
    }
  }

  div.wpcf7-mail-sent-ok {
    border: 1px solid #2DB205;
    background-color: rgba(45, 178, 5, 0.08);
    padding: 15px;
    @include font(14, 18, 500);
  }
}


// #CBCBCB  placeholder color

.search-pricelist-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .search-pricelist {
    min-width: 310px;
    border: 0;
    border-bottom: 1px solid #CBCBCB;
    @include font(14, 17);
    padding: 12px 15px;
    position: relative;
    transition: border 0.3s;

    &:focus {
      outline: none;
      border-bottom: 1px solid $orange;
    }
  }

}


.slick-active + .slick-active + .slick-active + .slick-active{
  pointer-events: none;
  opacity: 0.2;
}
.two-column-text-container {
  padding: 30px 15px;
  @include mq(bp-1200) {
    padding: 60px 15px;
  }
}
.career-list {
  max-width: 770px;
  margin: 0 auto;
  padding: 30px 0;
  margin-bottom: 60px;

  .career-title {
    color: #3D3D3D;
    text-align: center;
    padding: 15px 0;
    margin-bottom: 15px;
    @include font(14,18,500);
    @include mq(bp-991) {
      margin-bottom: 30px;
      @include font(18,22,500);
    }
  }
}














$radioSize: 16px;
$radioBorder: rgba(255,105,40,0.25);
$radioActive: $orange;





.nf-field-element li {
    margin: 16px 0;
    display: block;
    cursor: pointer;
    input {
        display: none;
        & + label {
            line-height: $radioSize;
            height: $radioSize;
            padding-left: $radioSize;
            display: block;
            position: relative;
            margin-bottom: 12px;
            color: #707070;
            cursor: pointer;
            &:not(:empty) {
                padding-left: $radioSize + 8;
            }
            &:before,
            &:after {
                content: '';
                width: $radioSize;
                height: $radioSize;
                display: block;
                border-radius: 50%;
                left: 0;
                top: 0;
                position: absolute;
            }
            &:before {
                background: $radioBorder;
                transition: background .2s ease, transform .4s cubic-bezier(.175, .885, .32, 2);
            }
            &:after {
                background: #fff;
                transform: scale(.78);
                transition: transform .6s cubic-bezier(.175, .885, .32, 1.4);
            }
        }
        &:checked + label {
        
            &:before {
                transform: scale(1.04);
                background: $radioActive;
            }
            &:after {
                transform: scale(.4);
                transition: transform .3s ease;
            }
            .radio-smile {
              opacity: 1;
            }
        }
       
    }
    &:hover {
        input {
            & + label {
                &:before {
                    transform: scale(.92);
                }
                &:after {
                    transform: scale(.74);
                }
            }
            &:checked + label {
                &:after {
                    transform: scale(.4);
                }
            }
        }
    }
}
.nf-form-content  {
  padding-left: 5px;
  @include mq(bp-991) {
    padding-left: 50px;
  }
  .label-above .nf-field-label label {

    @include mq(bp-991) {
    margin-bottom: 30px;
    }
    &::before {
      @include pseudo;
      background-color: $orange;
      width: 40px;
      height: 2px;
      left: -50px;
      top: 12px;

    }
  }
}

.nf-field-label label {
  color: #333333;
  position: relative;

  @include mq(bp-991) {
    @include font(18,22);
    margin-bottom: 30px;
  }


}
.radio-smile {
  width: 35px;
  height: 35px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 7px;
  display: block;
  position: absolute;
  opacity: 0.5;
  font-size: 0;
  cursor: pointer;
  left: -9px;
    top: -45px;
    @include mq(bp-991) {
      width: 50px;
      height: 50px;
      left: -17px;
      top: -60px;
    }
  &.radio_1{
    background-image: url(../img/icons/radio_1.svg);
  }
  &.radio_2{
    background-image: url(../img/icons/radio_2.svg);
  }
  &.radio_3{
    background-image: url(../img/icons/radio_3.svg);
  }
  &.radio_4{
    background-image: url(../img/icons/radio_4.svg);
  }
  &.radio_5{
    background-image: url(../img/icons/radio_5.svg);
  }
}

.buttons-with-image {

  .nf-field-element {
    padding-top: 70px!important;
    ul {
      display: flex;
      justify-content: space-between;
      max-width: 500px;
    
    }
  }


}
.nf-field-element textarea {
  border:1px solid rgba(255, 105, 40,0.25);
  &:focus {
    outline: none;
    border:1px solid rgba(255, 105, 40,1);
  }
}

.nf-form-fields-required {
  margin-bottom: 15px;
}
.thanks-for {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.slider-mobile-image {
  display: block;
  @include mq(bp-768) {
    display: none;
  }
  img {
    width: 100%;
  }
}