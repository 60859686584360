
.footer {
  background-color: #333333;
  padding: 60px 0;
  color: #FFF6F2;

  &__menu-center {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
    .menu-item {
      margin-bottom: 50px;
    }
    a {
      position: relative;
      &::after{
        @include pseudo();
        transition: all 0.3s;
        height: 18px;
        width: 1px;
        background-color: rgba(255,246,242,0.5);
        top: 50px;
        left: 0;
        right: 0;
        margin: 0 auto;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
      }
      &:hover {
        &::after{
          top: 20px;
          background-color: $orange;
          transform: rotate(-90deg);
          height: 45px;
  
        }
      }
    }
  }

.menu {
  padding-left: 0px;
  .menu-item {
    list-style: none;
    a {
      text-decoration: none;
      padding: 10px 0;
      display: block;
      transition: all 0.3s;
      &:hover {
        color: $orange;
      }
    }
  }
  &>.menu-item > a{
    color: #fff;
    text-transform: uppercase;
    @include font(12px,14px,700);
    @include letter-spacing(100);
  }

 
  .sub-menu {
    padding-left: 20px;
    padding-bottom: 20px;
    a {
      color: rgba(255,246,242,0.5);
      @include font(14px,17px);
      position: relative;
      &:hover {
        &::before {
          left: -17px;
        }
      }
      &::before {
        content: '+';
        position: absolute;
        transition: all 0.3s;
        color: rgba(255,255,255,0.15);
        left: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
&__title {
  color: #fff;
  text-transform: uppercase;
  @include font(12px,14px,700);
  @include letter-spacing(100);
  margin-bottom: 20px;
}
  &__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 40px;
    img {
      display: block;
      width: 320px;
      padding: 0 15px;
      max-width: 100%;
    }
  }
  &__icon {
    width: 36px;
    height: 36px;
    svg {
      width: 100%;
    }
    @include mq(bp-991) {
      width: 24px;
      height: 24px;
    }
    margin-right: 7px;
    display: block;
    background-size: contain;
    transition: all 0.1s;
    &:last-child {
      margin-right: 0;
    }
    .a{fill:#fff6f2;}
    &:hover {
      -webkit-box-shadow: 0px 0px 32px -6px #ff6928;
    -moz-box-shadow: 0px 0px 32px -6px #ff6928;
    box-shadow: 0px 0px 32px -6px #ff6928;
    border-radius: 50%;
      .a{fill:#FF6928;}
      transform: scale(1.2);
    }
   
  }
  &__address {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
   
    @include font(12,18);
    color: rgba(255,246,242,0.5);
    @include mq(bp-991) {
      flex-direction: column;
      flex: 0 0 50%;
      max-width: 50%;
    }
    ul {
      list-style: none;
      padding: 0;
    }

    .address {
     padding: 0;
     width: 50%;
     list-style: none;
     @include mq(bp-991) {
      width: auto;
     }
    }
    .open-hour {
     
      width: 50%;
      @include mq(bp-991) {
       width: auto;
      }
    }
  }
  &__social {
    display: flex;
    justify-content: center;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px 0;
    @include mq(bp-991) {
      padding: 0;
      justify-content: flex-end;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
 
}


.newsletter-rodo {
  color: rgba(255,246,242,0.5);
  @include font(9,14)
}
.newsletter-input {
  background: transparent;
  border: 1px solid rgba(255,255,255,0.1);
  @include font(12,15);
  width: calc(100% - 110px);
  color: #fff;
  padding: 2px 15px;

  &:focus {
    outline: none;
    border: 1px solid $orange;
  }
}

.newsletter-footer {
  margin-top: 15px;
  @include mq(bp-400,max) {
.newsletter-input {
  width: 100%;
  padding: 10px ;
  margin-bottom: 10px;
}
.btn.submit,
.input-group-append {
  width: 100%;
}

  }
}