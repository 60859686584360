$orange : #FF6928;
$orange-light : #FF8F5E;

$overlay: rgba(57,28,15,0.1);

$breakpoints: (
"bp-340": 21.25em,
"bp-360": 22.500em,
"bp-400": 25em, // 
"bp-480": 30em, // 
"bp-560": 35em, // 
"bp-768": 48em, // 768px
"bp-991": 62em, // 991px
"bp-1024": 64em, // 1024px // 1024px
"bp-1200": 75em, //
"bp-1248": 78em, //
"bp-1366": 85.375em, // 1366px
"bp-1440": 90em, // 1440px
"bp-1600": 100em,
"bp-1920": 120em, // 1920px
"bp-2560": 160em // 2560px
);